import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
    Select,
    InputNumber,
    Radio,
} from "antd";
import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import { exportExcel } from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";

const { Content } = Layout;

const AdminBusCommissions = () => {
    const { Option } = Select;
    const { user } = useAuthContext();
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [busCommList, setBusCommList] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [membershipList, setMembershipList] = useState([]);
    const [operatorsList, setOperatorsList] = useState([]);
    const [apiOptions, setApiOptions] = useState([]);

    useEffect(() => {
        getBusCommList();
        //  getSuppliersList();
        getOperatorsList();
    }, []);

    const getOperatorsList = () => {
        let page = { page: 0 };
        ApiClient.post("operators/getoperator/list", page)
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.results.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                        };
                    });
                    setOperatorsList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // const getSuppliersList = () => {
    //     setApiOptions([]);
    //     let page = { page: 0 };
    //     ApiClient.post("supplier/getsupplier/list", page)
    //         .then((res) => {
    //             if (res.isSuccess) {
    //                 setApiOptions(res.results);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    let supplierList =
        [{ name: 'IRCT', value: 1 },
        { name: 'REDB', value: 2 },
        { name: 'BTLA', value: 3 },
        { name: 'TRYR', value: 4 },
        { name: 'EZIF', value: 6 },
        { name: 'ETST', value: 7 },
        { name: 'YLBS', value: 8 },
        { name: 'ZGBS', value: 9 },
        { name: 'ITSG', value: 10 },
        { name: 'VRLT', value: 11 },
        { name: 'ABHI', value: 12 }]

    // const getMembershipList = () => {
    //     ApiClient.get("codes/getmembership")
    //         .then((res) => {
    //             if (res.isSuccess) {
    //                 let data = res.data;
    //                 setMembershipList(data);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };

    const getBusCommList = () => {

        ApiClient.get("commission/getadmincommission")
            .then((res) => {
                if (res.isSuccess) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            gstValue: item.gstValue.toFixed(2)
                        };
                    });

                    setBusCommList(data);
                    setTableList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const add = (val) => {
        let obj = { ...val, operatorId: val?.operatorId ?? 0 }
        ApiClient.post("commission/saveadmincommission", obj)
            .then((response) => {
                return response;
            })
            .then((response) => {
                if (response.isSuccess) {
                    setModalVisible(false);
                    message.success("Successfully added AdminCommission", 3);
                    getBusCommList();
                } else if (!response.isSuccess) {
                    message.error("AdminCommission Already existed", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const deleteBusCommList = (Record) => {
        let Id = Record.id;

        ApiClient.delete("commission/deletebuscommission/" + Id)
            .then((res) => {
                if (res.isSuccess) {
                    message.success("BusCommissions Deleted  successfully", 3);
                    getBusCommList();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const updateBusCommList = (val) => {
        ApiClient.put(
            `commission/updateadmincommission/${currentId.id}`,
            {},
            val
        ).then((resp) => {
            if (resp.isSuccess) {
                getBusCommList();
                setModalVisible(false);
                message.success("AdminCommissions Updated successfully", 3);
                setCurrentId(-1);
            }
        });
    };

    const submitForm = (val) => {
        let obj = {
            ...val,
        };
        if (showUpdateBtn) updateBusCommList(obj);
        else add(obj);
    };

    const tableActions = (currentRecord, Name) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentRecord, Name)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                {/* <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteBusCommList(currentRecord)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div> */}
            </div>
        );
    };

    const editCurrentItem = (id, data) => {
        setShowUpdateBtn(true);
        setCurrentId(id);
        form.setFieldsValue({
            ...data,
            commissionType: getType(data.commissionType),
            service: data.service == "Buses" ? 1 : 0
        });
        setModalVisible(true);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };
    const getType = (type) => {
        switch (type) {
            case 1:
                return "Fixed ";
            case 2:
                return "Percentage ";
            default:
                return "";
        }
    };
    const searchData = () => {
        setShowSearchBox(true);
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "SNo",
            sorter: (a, b) => a.SNo - b.SNo,
        },
        {
            title: "Supplier",
            dataIndex: "supplierName",
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Operater",
            dataIndex: "operaterName",
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Commission",
            dataIndex: "commission",
            sorter: (a, b) => a.commission - b.commission,
        },
        {
            title: "Commission Type",
            dataIndex: "commissionType",
            sorter: (a, b) => a.commissionType - b.commissionType,
            render: (commissionType) => getType(commissionType),
        },
        // {
        //   title: "Rtc",
        //   dataIndex: "isRtc",
        //   sorter: (a, b) => a.name.localeCompare(b.name),
        // },
        {
            title: "GST Type",
            dataIndex: "gstType",
        },
        {
            title: "GST Value",
            dataIndex: "gstValue",
            sorter: (a, b) => a.gstValue - b.gstValue,
            // render: (markType) => getType(markType),
        },

        {
            title: "Actions",
            render: (id, name) =>
                user?.roleId === 1 ? tableActions(id, name) : null,
        },
    ];

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(busCommList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(busCommList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            service: "Buses",
            supplierId: item.supplierId,
            operatorId: item.operatorId,
            commission: item.commission,
            commissionType: item.commissionType,
            gstType: item.gstType,
            gstValue: item.gstValue,
            isRtc: item.isRtc,
            isActive: item.isActive
        }));

        exportExcel(excelData, "BusCommissions");
    };

    return (
        <Layout>
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false}>
                            <div className="card-add-heading">
                                <div className="rows-count">
                                    <h5>View AdminBusCommissions</h5>
                                    <p>{tableList.length} rows found !</p>
                                </div>
                                <div className="action-images">
                                    {showSearchBox && (
                                        <Input
                                            autocomplete="newpassword"
                                            placeholder="Search"
                                            onChange={(e) => searchTableData(e)}
                                            suffix={closeSearchInput}
                                            style={{ padding: "0px 12px" }}
                                        />
                                    )}
                                    &nbsp;&nbsp;
                                    <img src={search} alt="search" onClick={() => searchData()} />
                                    <img src={excel} alt="excel" onClick={() => handleExcel()} />
                                    <img
                                        src={exchange}
                                        alt="exchange"
                                        onClick={() => {
                                            setTableList([]);
                                            getBusCommList();
                                        }}
                                    />
                                    {user?.roleId === 1 ? (
                                        <p className="add-deposit-icon" onClick={handleModal}>
                                            <PlusCircleFilled />
                                        </p>
                                    ) : null}
                                </div>
                            </div>

                            <Table
                                scroll={{ x: true }}
                                bordered
                                rowKey={uniqueKey}
                                dataSource={tableList}
                                columns={columns}
                                pagination={{
                                    defaultPageSize: 25,
                                    showSizeChanger: true,
                                    pageSizeOptions: ["25", "50", "100", "125"],
                                }}
                            />
                        </Card>
                    </div>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>
                                {showUpdateBtn ? "Update AdminBusCommissions" : "Add AdminBusCommissions"}
                            </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header bci-panel-admin-parts"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout="vertical" form={form} onFinish={submitForm}>
                    <Row gutter={12}>
                        <Col md={12} xs={24}>
                            <Form.Item label="Supplier" name="supplierId" rules={[{ required: true, message: "Required" }]}>
                                <Select placeholder="Please select Supplier">
                                    {supplierList.map((item) => (
                                        <Option value={item.value}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                            <Form.Item label="Operator" name="operatorId">
                                <Select placeholder="Please select Operator Type">
                                    {operatorsList.map((item) => (
                                        <Option value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                            <Form.Item
                                label="Service "
                                name="service"
                                rules={[{ required: true, message: "Required" }]}
                            >
                                <Select placeholder="Please select Service">
                                    <Option value={1}>Buses</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item
                                label="Commission Type"
                                name="commissionType"
                                rules={[
                                    { required: true, message: "Please enter Commission Type" },
                                ]}
                            >
                                <Select placeholder="Please select Commission Type">
                                    <Option value="Fixed">Fixed</Option>
                                    <Option value="Percentage">Percentage</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item
                                label="Commission"
                                name="commission"
                                rules={[{ required: true, message: "Please enter Commission" }]}
                            >
                                <InputNumber
                                    autocomplete="newpassword"
                                    placeholder="Please Enter Commission"
                                    style={{ width: "100%" }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                            <Form.Item
                                label="GST Type"
                                name="gstType"
                                rules={[
                                    { required: true, message: "Please enter GST Type" },
                                ]}
                            >
                                <Select placeholder="Please select GST Type">
                                    <Option value="Inclusive">Inclusive</Option>
                                    <Option value="Exclusive ">Exclusive </Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                            <Form.Item
                                label="GST Value"
                                name="gstValue"
                                rules={[{ required: true, message: "Please enter GST Value" }]}
                            >
                                <InputNumber
                                    autocomplete="newpassword"
                                    placeholder="Please Enter GST Value"
                                    style={{ width: "100%" }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                            <Form.Item label="RTC" name="isRtc">
                                <Radio.Group>
                                    <Radio value={true}>True</Radio>
                                    <Radio value={false}>False</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Active" name="isActive">
                                <Radio.Group>
                                    <Radio value={true}>True</Radio>
                                    <Radio value={false}>False</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    );
};
export default AdminBusCommissions;
